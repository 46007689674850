import React, { useLayoutEffect, useRef, useState } from "react";
import Page from "../components/Common/Page";
import IndexLayout from "../layouts";
//@ts-ignore
import BackgroundImage from "../assets/pages/genetic/MediTree.jpg";
import MainSection from "../components/pages/GeneticPage/MainSection";
import PictureSection from "../components/pages/GeneticPage/PictureSection";

const getNewState = (item: string, isTop?: boolean) => {
  return { ...defaultShow, [item]: true, navbar: isTop ? false : true };
};

const defaultShow = {
  itemOne: false,
  itemTwo: false,
  itemThree: false,
  itemFour: false,
  itemFive: false,
  itemSix: false,
  itemSeven: false,
};

function Genetics() {
  const [check, setCheck] = useState(false);
  const [show, setShow] = useState<{ [key: string]: boolean }>({ ...defaultShow, navbar: false });

  const [activeStep, setActiveStep] = useState("#banner");

  const refNavbar = useRef(null);
  const refOne = useRef(null);
  const refTwo = useRef(null);
  const refThree = useRef(null);
  const refFour = useRef(null);
  const refFive = useRef(null);
  const refSix = useRef(null);

  useLayoutEffect(() => {
    const bottomPos = (element: any) => element?.getBoundingClientRect().bottom + window.scrollY;

    const section1Bottom = bottomPos(refOne.current);
    const section2Bottom = bottomPos(refTwo.current);

    const onScroll = () => {
      if (window) {
        const scrollPos = window.scrollY + window.innerHeight;
        // Inside Nav
        // if (window.scrollY < 16) {
        //   handleSetShow("itemOne", true);
        // }
        // Section1
        if (scrollPos < section1Bottom + 16) {
          if (!show["itemOne"]) {
            setShow((prev: any) => ({ ...prev, ["itemOne"]: true, navbar: false }));
            setActiveStep("#banner");
          }
          // const percent = Math.round(((scrollPos - window.innerHeight) / (section1Bottom - window.innerHeight)) * 100);
        }
        // Section2
        else if (section1Bottom + 16 < scrollPos && scrollPos < section2Bottom) {
          if (!show["itemTwo"]) {
            setShow((prev: any) => ({ ...prev, ["itemTwo"]: true, navbar: true }));
            setActiveStep("#productlist");
          }
        }
      }
    };

    const initialProp = () => {
      onScroll();
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll);
    };

    const changeCheck = () => {
      setCheck(!check);
    };

    if (window) {
      window.addEventListener("scroll", onScroll);
      window.addEventListener("resize", changeCheck);
      initialProp();
    }
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", changeCheck);
    };
  }, [check]);

  return (
    <IndexLayout ref={refNavbar} animate={show.navbar}>
      <Page>
        <MainSection ref={refOne} animate={show.itemOne}/>
        <PictureSection ref={refTwo} animate={show.itemTwo} />
      </Page>
    </IndexLayout>
  );
}

export default Genetics;
