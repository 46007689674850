import React, { forwardRef } from "react";
//@ts-ignore
import BackgroundImage from "../../../assets/pages/genetic/MediTree.jpg";

interface PictureSectionProps {
  className?: string;
  animate: boolean;
}

const PictureSection = forwardRef(({ className, animate }: PictureSectionProps, ref: any) => {
  return (
    <div
      className="relative w-screen h-[fit-content] bg-center bg-cover bg-no-repeat transition-all ease-out duration-2500 translate-y-10"
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      ref={ref}
    >
      <section
        className="w-screen mb-10 xl:h-[665px] md:h-[500px] sm:h-[400px] h-[350px]"
        style={{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
      
      </section>
    </div>
  );
});

export default PictureSection;
