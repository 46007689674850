import React, { forwardRef } from "react";
import Tree from "../../../assets/pages/genetic/Tree.svg";
import { BannerText } from "../../Common/BannerText";

interface MainSectionProps {
  className?: string;
  animate: boolean;
}

const MainSection = forwardRef(({ className, animate }: MainSectionProps, ref: any) => {
  return (
    <div className="relative w-screen min-h-screen sm:h-screen bg-center bg-cover bg-no-repeat" ref={ref}>
      <section className="bg-medBgGray w-screen min-h-screen flex flex-col items-center">
        {/* <h1
          className="pt-[140px] pb-10 md:pb-14 lg:pb-20 
            text-h2 md:text-h1 text-medMainGreen text-center 
            transition-all ease-out duration-2500 translate-y-10"
          style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
        >
          Genetics
        </h1> */}
        <BannerText animate={animate}>Genetics</BannerText>
        <div
          className="mt-10 md:mt-18 mx-8 sm:mx-20 md:mx-30 gap-x-20 items-center lg:w-[960px] flex flex-col md:flex-row transition-all ease-out duration-2500 translate-y-10"
          style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
        >
          <p className="text-medMainGreen text-2xl text-center mb-6 md:mb-0">
            Our facility is designed to maintain the highest quality mother plants and clones, and with our pheno-hunting programs we can ensure that
            our future Genetics library contains the best and consistent cultivars, with high cannabinoid contents, unique flavors, and top-shelf
            craft cannabis experience.
          </p>
          <img src={Tree} alt="logo" className="h-[200px] sm:h-[310px] md:h-[443px]" />
        </div>
      </section>
    </div>
  );
});

export default MainSection;
